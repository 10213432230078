import React from 'react';
import { MainPageLayout } from '../layout/MainPageLayout';
import { FactoryNavigationBar } from '../component/navigation-bar/FactoryNavigationBar';
import { CloudUserDiv } from '../component/cloud-user/CloudUserDiv';

export const CloudUserPage = () => (
  <MainPageLayout
    navigationBar={<FactoryNavigationBar />}
    elementCentr={<CloudUserDiv />}
  />
);

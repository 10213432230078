import React, { FunctionComponent } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

interface Props {
  label: any;
  disabled?: boolean;
  value?: any;
  onChange?: (e: any) => void;
}

export const MyCheckBox: FunctionComponent<Props> = ({
  label,
  disabled,
  value,
  onChange,
}) => (
  <FormControlLabel
    control={
      <Checkbox checked={value} onChange={onChange} disabled={disabled} />
    }
    label={label}
  />
);

MyCheckBox.defaultProps = {
  value: '',
  disabled: false,
  onChange: undefined,
};

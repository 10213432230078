import React, { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import StorageIcon from '@mui/icons-material/Storage';
import TokenIcon from '@mui/icons-material/Token';
import PatternIcon from '@mui/icons-material/Pattern';
import CloudIcon from '@mui/icons-material/Cloud';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { getDefaultAlertProps, getRootLevel } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { FactoryStageEnum } from '../../enum/FactoryStageEnum';
import { FactoryDivGridItem } from './FactoryDivGridItem';
import { FactoryBodyHeaderDiv } from '../body-header/FactoryBodyHeaderDiv';

export const FactoryDiv = () => {
  const root = getRootLevel();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <FactoryBodyHeaderDiv title={t('dashboard')} />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Grid container spacing={1}>
            <FactoryDivGridItem
              image={StorageIcon}
              color="#009688"
              param={FactoryStageEnum.LOCAL_SERVER_ACTIVATE}
            />
            <FactoryDivGridItem
              image={PrecisionManufacturingIcon}
              color="#ff5722"
              param={FactoryStageEnum.FACTORY_TOKEN}
            />
            <FactoryDivGridItem
              image={TokenIcon}
              color="#e91e63"
              param={FactoryStageEnum.SERVICE_TOKEN}
            />
            <FactoryDivGridItem
              image={FactCheckIcon}
              color="#795548"
              param={FactoryStageEnum.LICENSE_RESULT}
            />
            <FactoryDivGridItem
              image={PatternIcon}
              color="#2196f3"
              param={FactoryStageEnum.PASSWORDS}
            />
            <FactoryDivGridItem
              image={CloudIcon}
              color="#4caf50"
              param={FactoryStageEnum.CLOUD_USER}
            />
            {root === 100 && (
              <FactoryDivGridItem
                image={BatteryAlertIcon}
                color="#9c27b0"
                param={FactoryStageEnum.BATTERY_ACTIVATE}
              />
            )}
          </Grid>
        </Stack>
      </Stack>
    </div>
  );
};

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SpeedDial from '@mui/material/SpeedDial';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddLinkIcon from '@mui/icons-material/AddLink';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GroupsIcon from '@mui/icons-material/Groups';
import TelegramIcon from '@mui/icons-material/Telegram';
import TokenIcon from '@mui/icons-material/Token';
import AddTaskIcon from '@mui/icons-material/AddTask';
import {
  APP_TOKEN_ROUTE,
  LOG_ROUTE,
  MAIN_ROUTE,
  SERVICE_ROUTE,
} from '../../constant/routes';
import { DialogReport } from '../dialog/DialogReport';
import { DialogTask } from '../dialog/DialogTask';
import { DialogSetup } from '../dialog/DialogSetup';
import { DialogAccount } from '../dialog/DialogAccount';
import { DialogLinkFlowmeter } from '../dialog/DialogLinkFlowmeter';
import { getIsCloud, getRootLevel } from '../../utils/utils';
import { LEVEL_ADMIN, LEVEL_READ_WRITE } from '../../constant/constants';
import i18n from '../../localization/Localization';
import { DialogUploadArch } from '../dialog/DialogUploadArch';
import { DialogTelegram } from '../dialog/DialogTelegram';

const actionsCloud = [
  {
    icon: <AccountTreeIcon sx={{ color: '#29b6f6' }} />,
    name: 'Групповой отчет',
    tooltip: i18n.t('tooltipGroupPrint'),
  },
];

const actionsRead = [
  {
    icon: <AccountTreeIcon sx={{ color: '#29b6f6' }} />,
    name: 'Групповой отчет',
    tooltip: i18n.t('tooltipGroupPrint'),
  },
];

const actionsReadWrite = [
  {
    icon: <AccountTreeIcon sx={{ color: '#29b6f6' }} />,
    name: 'Групповой отчет',
    tooltip: i18n.t('tooltipGroupPrint'),
  },
  {
    icon: <AddTaskIcon sx={{ color: '#ef5350' }} />,
    name: 'Групповая задача',
    tooltip: i18n.t('tooltipGroupTask'),
  },
  {
    icon: <FileUploadIcon sx={{ color: '#ab47bc' }} />,
    name: 'Загрузка архива',
    tooltip: i18n.t('tooltipUploadArch'),
  },
  {
    icon: <TokenIcon sx={{ color: '#e91e63' }} />,
    name: 'Токены',
    tooltip: i18n.t('tooltipToken'),
  },
];

const actionsAdmin = [
  {
    icon: <AccountTreeIcon sx={{ color: '#29b6f6' }} />,
    name: 'Групповой отчет',
    tooltip: i18n.t('tooltipGroupPrint'),
  },
  {
    icon: <AddTaskIcon sx={{ color: '#ef5350' }} />,
    name: 'Групповая задача',
    tooltip: i18n.t('tooltipGroupTask'),
  },
  {
    icon: <GroupsIcon sx={{ color: '#26a69a' }} />,
    name: 'Редактирование групп',
    tooltip: i18n.t('tooltipEditDroup'),
  },
  {
    icon: <ManageAccountsIcon sx={{ color: '#ff7043' }} />,
    name: 'Личные кабинеты',
    tooltip: i18n.t('tooltipAccount'),
  },
  {
    icon: <TelegramIcon sx={{ color: '#3f51b5' }} />,
    name: 'Привязка телеграм',
    tooltip: i18n.t('tooltipTelegram'),
  },
  {
    icon: <AddLinkIcon sx={{ color: '#26c6da' }} />,
    name: 'Привязка счетчика к пользователю',
    tooltip: i18n.t('tooltipLink'),
  },
  {
    icon: <SettingsIcon sx={{ color: '#ffca28' }} />,
    name: 'Сервис',
    tooltip: i18n.t('tooltipService'),
  },
  {
    icon: <FileUploadIcon sx={{ color: '#ab47bc' }} />,
    name: 'Загрузка архива',
    tooltip: i18n.t('tooltipUploadArch'),
  },
  {
    icon: <TokenIcon sx={{ color: '#e91e63' }} />,
    name: 'Токены',
    tooltip: i18n.t('tooltipToken'),
  },
  {
    icon: <HistoryEduIcon sx={{ color: '#9e9e9e' }} />,
    name: 'Журнал',
    tooltip: i18n.t('tooltipLog'),
  },
];

export const SpeedDialDiv = () => {
  const [openDialogReport, setOpenDialogReport] = useState(false);
  const [openDialogTask, setOpenDialogTask] = useState(false);
  const [openDialogSetup, setOpenDialogSetup] = useState(false);
  const [openDialogTelegram, setOpenDialogTelegram] = useState(false);
  const [openDialogAccount, setOpenDialogAccount] = useState(false);
  const [openDialogUserLink, setOpenDialogUserLink] = useState(false);
  const [openDialogUploadArch, setOpenDialogUploadArch] = useState(false);

  const root = getRootLevel();
  const isCloud = getIsCloud();

  const handleOpenUpload = () => {
    setOpenDialogUploadArch(true);
  };

  const handleCloseUpload = () => {
    setOpenDialogUploadArch(false);
  };

  const handleOpenReport = () => {
    setOpenDialogReport(true);
  };

  const handleCloseReport = () => {
    setOpenDialogReport(false);
  };

  const handleOpenTask = () => {
    setOpenDialogTask(true);
  };

  const handleCloseTask = () => {
    setOpenDialogTask(false);
  };

  const handleOpenSetup = () => {
    setOpenDialogSetup(true);
  };

  const handleCloseSetup = () => {
    setOpenDialogSetup(false);
  };

  const handleOpenAccount = () => {
    setOpenDialogAccount(true);
  };

  const handleCloseAccount = () => {
    setOpenDialogAccount(false);
  };

  const handleOpenTelegram = () => {
    setOpenDialogTelegram(true);
  };

  const handleCloseTelegram = () => {
    setOpenDialogTelegram(false);
  };

  const handleOpenUserLink = () => {
    setOpenDialogUserLink(true);
  };

  const handleCloseUserLink = () => {
    setOpenDialogUserLink(false);
  };

  const getActions = (rootLevel: number) => {
    if (isCloud) {
      return actionsCloud;
    }
    if (rootLevel === LEVEL_READ_WRITE) {
      return actionsReadWrite;
    }
    if (rootLevel === LEVEL_ADMIN) {
      return actionsAdmin;
    }
    return actionsRead;
  };

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = (name: string) => {
    if (name === 'Главная') {
      navigate(MAIN_ROUTE);
    }
    if (name === 'Групповая задача') {
      handleOpenTask();
    }
    if (name === 'Групповой отчет') {
      handleOpenReport();
    }
    if (name === 'Редактирование групп') {
      handleOpenSetup();
    }
    if (name === 'Личные кабинеты') {
      handleOpenAccount();
    }
    if (name === 'Привязка телеграм') {
      handleOpenTelegram();
    }
    if (name === 'Привязка счетчика к пользователю') {
      handleOpenUserLink();
    }
    if (name === 'Сервис') {
      navigate(SERVICE_ROUTE);
    }
    if (name === 'Загрузка архива') {
      handleOpenUpload();
    }
    if (name === 'Токены') {
      navigate(APP_TOKEN_ROUTE);
    }
    if (name === 'Журнал') {
      navigate(LOG_ROUTE);
    }
    setOpen(false);
  };

  return (
    <div>
      {!isCloud && (
        <SpeedDial
          ariaLabel=""
          sx={{
            '& .MuiFab-primary': {
              backgroundColor: '#9e9e9e',
              width: 5,
              height: 5,
              visibility: 'hidden',
            },
          }}
          direction="right"
          icon={<SpeedDialIcon openIcon={<MoreVertIcon />} />}
          onClick={handleOpen}
          open={open}
        >
          {getActions(root).map((action) => (
            <SpeedDialAction
              key={action.name}
              tooltipTitle={action.tooltip}
              icon={action.icon}
              onClick={() => handleClose(action.name)}
            />
          ))}
        </SpeedDial>
      )}
      <DialogReport open={openDialogReport} onClose={handleCloseReport} />
      <DialogTask open={openDialogTask} onClose={handleCloseTask} />
      <DialogSetup open={openDialogSetup} onClose={handleCloseSetup} />
      <DialogAccount open={openDialogAccount} onClose={handleCloseAccount} />
      <DialogTelegram open={openDialogTelegram} onClose={handleCloseTelegram} />
      <DialogLinkFlowmeter
        open={openDialogUserLink}
        onClose={handleCloseUserLink}
      />
      <DialogUploadArch
        open={openDialogUploadArch}
        onClose={handleCloseUpload}
      />
    </div>
  );
};

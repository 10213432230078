import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Divider, Stack, Typography } from '@mui/material';
import { MyButton } from '../UI/MyButton';
import { MyPasswordTextField } from '../UI/MyPasswordTextField';
import { MyTextFiled } from '../UI/MyTextField';
import { IAlertProps } from '../../interface/IAlertProps';
import { formatNumberToBool, getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import {
  FULLNAME_NAME,
  IP_LOCAL_NAME,
  IS_CLOUD_NAME,
  MAIN_COLOR_STRING,
  ROOT_NAME,
  TOKEN_NAME,
} from '../../constant/constants';
import { SignInDto } from '../../dto/SignInDto';
import { FACTORY_ROUTE, MAIN_ROUTE } from '../../constant/routes';
import { IApiResult } from '../../interface/IApiResult';
import { apiSignIn } from '../../service/account/apiSignIn';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyCheckBox } from '../UI/MyCheckBox';

export const SignInDiv = () => {
  // localStorage.clear();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [signInDto, setSignInDto] = useState<SignInDto>({
    localIp: localStorage.getItem(IP_LOCAL_NAME) ?? '',
    login: '',
    password: '',
    isCloud: localStorage.getItem(IS_CLOUD_NAME) === '1' ? 1 : 0,
  });
  const [checkHelper, setCheckHelper] = useState(false);

  const signIn = async () => {
    const apiResult: IApiResult = await apiSignIn(signInDto);
    if (apiResult.isSuccess) {
      localStorage.setItem(IP_LOCAL_NAME, signInDto.localIp);
      localStorage.setItem(TOKEN_NAME, apiResult.data.token);
      localStorage.setItem(FULLNAME_NAME, apiResult.data.name);
      localStorage.setItem(ROOT_NAME, apiResult.data.root);
      localStorage.setItem(IS_CLOUD_NAME, signInDto.isCloud.toString());
      if (apiResult.data.root === 10 || apiResult.data.root === 100) {
        navigate(FACTORY_ROUTE);
      } else {
        navigate(MAIN_ROUTE);
      }
    } else if (apiResult.status === 404) {
      setAlertProps({
        message: t('errotNotFoundAccount'),
        severity: AlertSeverityEnum.error,
      });
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSignIn = async () => {
    if (
      signInDto.login === '' ||
      signInDto.password === '' ||
      (signInDto.localIp === '' && signInDto.isCloud === 0)
    ) {
      setCheckHelper(true);
      return;
    }
    await signIn();
  };

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '15%', minWidth: '250px' }}>
        <Stack width="100%" spacing={2}>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '24px',
              letterSpacing: 1,
              color: '#000000',
            }}
            component="text"
          >
            {t('signIn')}
          </Typography>
          <Divider />
          {signInDto.isCloud === 0 && (
            <MyTextFiled
              value={signInDto.localIp}
              onChange={(e) =>
                setSignInDto({ ...signInDto, localIp: e.target.value })
              }
              autoComplete="off"
              size="small"
              error={checkHelper}
              label={t('ip')}
            />
          )}
          <MyTextFiled
            value={signInDto.login}
            onChange={(e) =>
              setSignInDto({ ...signInDto, login: e.target.value })
            }
            autoComplete="on"
            size="small"
            error={checkHelper}
            label={t('login')}
          />
          <MyPasswordTextField
            value={signInDto.password}
            onChange={(e) =>
              setSignInDto({ ...signInDto, password: e.target.value })
            }
            size="small"
            error={checkHelper}
            label={t('password')}
          />
          <MyButton
            text={t('enter')}
            background={MAIN_COLOR_STRING}
            onClick={handleSignIn}
          />
          <MyCheckBox
            value={signInDto.isCloud === 1 ? 'true' : ''}
            onChange={(e) =>
              setSignInDto({
                ...signInDto,
                isCloud: e.target.checked ? 1 : 0,
              })
            }
            label={t('cloudNeo')}
          />
        </Stack>
      </Card>
    </Stack>
  );
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
} from '@mui/material';
import {
  getDefaultAlertProps,
  getSelectedLng,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import {
  LANGUAGE_RUS,
  MAIN_COLOR_STRING,
  PAGE_SIZE_DEVICES,
  START_PAGE_SIZE_DEVICES,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyTextFiled } from '../UI/MyTextField';
import { FactoryBodyHeaderDiv } from '../body-header/FactoryBodyHeaderDiv';
import { LocalServerDto } from '../../dto/LocalServerDto';
import { apiGetLocalServer } from '../../service/local-server-license/apiGetLocalServer';
import { apiDeleteLocalServer } from '../../service/local-server-license/apiDeleteLocalServer';
import { apiAddLocalServer } from '../../service/local-server-license/apiAddLocalServer';

export const LocalServerDiv = () => {
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [localServerDto, setLocalServerDto] = useState<LocalServerDto>({
    id: -1,
    serverId: '',
    name: '',
    organization: '',
    dateFrom: -1,
    dateTo: -1,
  });
  const [localServerDtos, setLocalServerDtos] = useState<LocalServerDto[]>([]);

  const getLocalServer = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetLocalServer();
    if (apiResult.isSuccess) {
      setLocalServerDtos(apiResult.data.localServer);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const deletLocalServer = async (dto: LocalServerDto) => {
    const apiResult: IApiResult = await apiDeleteLocalServer(dto);
    if (apiResult.isSuccess) {
      getLocalServer();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const addLocalServer = async (dto: LocalServerDto) => {
    const apiResult: IApiResult = await apiAddLocalServer(dto);
    if (apiResult.isSuccess) {
      getLocalServer();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const [checkHelper, setCheckHelper] = useState(false);

  const handelCreate = async () => {
    if (localServerDto?.serverId === '' || localServerDto?.name === '') {
      setCheckHelper(true);
      return;
    }
    await addLocalServer(localServerDto);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  useEffect(() => {
    getLocalServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'organization',
      headerName: t('headerServerOrganization'),
      renderHeader: () => <strong>{t('headerServerOrganization')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.organization,
    },
    {
      field: 'name',
      headerName: t('headerServerName'),
      renderHeader: () => <strong>{t('headerServerName')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'serverId',
      headerName: t('headerServerId'),
      renderHeader: () => <strong>{t('headerServerId')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.serverId,
    },
    {
      field: 'dateFrom',
      headerName: t('headerServerDateFrom'),
      renderHeader: () => <strong>{t('headerServerDateFrom')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => unixToLocal(row.row.dateFrom),
    },
    {
      field: 'dateTo',
      headerName: t('headerServerDateTo'),
      renderHeader: () => <strong>{t('headerServerDateTo')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => unixToLocal(row.row.dateTo),
    },
    {
      field: 'id',
      type: 'actions',
      width: 70,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            size="small"
            title={t('tooltipDelete')}
            onClick={() => deletLocalServer(row.row)}
          >
            <DeleteForeverIcon
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <FactoryBodyHeaderDiv title={t('localServer')} />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Stack direction="row" spacing={1}>
            <MyTextFiled
              value={localServerDto.organization}
              autoComplete="off"
              onChange={(e) =>
                setLocalServerDto({
                  ...localServerDto,
                  organization: e.target.value,
                })
              }
              size="small"
              error={checkHelper}
              label={t('textServerOrganization')}
            />
            <MyTextFiled
              value={localServerDto.name}
              autoComplete="off"
              onChange={(e) =>
                setLocalServerDto({ ...localServerDto, name: e.target.value })
              }
              size="small"
              error={checkHelper}
              label={t('textServerName')}
            />
            <MyTextFiled
              value={localServerDto.serverId}
              onChange={(e) =>
                setLocalServerDto({
                  ...localServerDto,
                  serverId: e.target.value,
                })
              }
              autoComplete="off"
              size="small"
              error={checkHelper}
              label={t('textServerId')}
            />
            <Stack alignItems="center">
              <IconButton
                sx={{ color: MAIN_COLOR_STRING }}
                onClick={handelCreate}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Box
            sx={{
              '& .app-theme-cell': {
                fontFamily: 'sans-serif',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <DataGrid
                sx={{
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: 1,
                    fontSize: '100px',
                  },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '5px',
                  },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '10px',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                    {
                      outline: 'none !important',
                    },
                }}
                getRowHeight={() => 'auto'}
                columns={columns}
                rows={localServerDtos}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...other}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={PAGE_SIZE_DEVICES}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </ThemeProvider>
          </Box>
        </Stack>
      </Stack>
    </div>
  );
};
